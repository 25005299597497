footer {
  background: var(--bdr-color1);
  padding: .5rem 0;
  text-align: center;
  font-size: .9rem;
  margin-top: 6.5rem;
}

.footer-logo {
  display: inline-block;
  max-width: 10rem;
  margin-bottom: 2rem;
}

.permalinks,
.socials {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 3rem;
}

a:hover {
  color: var(--btn-color1);
}


.footer-copyright {
  margin-bottom: 6rem;

}