.projectsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
}

.project-item {
  background: var(--btn-color2);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 2px solid transparent;
  transition: var(--transition);
}

.project-item:hover {
  transform: scale(1.1);
}

.project-img {
  border-radius: .5rem;
  overflow: hidden;
}

.project-item h3 {
  margin: 1.2rem 0 .5rem;
  text-align: center;
}

.project-item h4 {
  text-align: center;
  margin-bottom: 1rem;
}

.ctaBtns {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  ;
}

.cta {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  gap: 1.2rem;

}

@media screen and (max-width: 1024px) {
  .projectsContainer {
    grid-auto-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

@media screen and (max-width: 600px) {
  .projectsContainer {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}