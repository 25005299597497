h2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  padding-top: 1rem;
}

.github-projects {
  color: var(--btn-color1);
}

.github-projects:hover {
  color: var(--bdr-color2);

}


.aboutContainer {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
}

.about-me {
  width: 100%;
  /* height will always match width*/
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(80deg, transparent, var(--bdr-color2), transparent);
  display: grid;
  place-items: center;
}

.about-me-img {
  border-radius: 1rem;
  overflow: hidden;
  transform: rotate(15deg);
  height: max-content;
  transition: var(--transition);
}

.about-me-img:hover {
  transform: rotate(0);
}

.about-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.about-card {
  background: var(--bdr-color1);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: var(--transition);
}

.about-card:hover {
  background: transparent;
  border-color: var(--bdr-color1);
  cursor: default;
}

.about-icon {
  color: var(--btn-color1);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about-card h5 {
  font-size: 15px;
}

.about-card small {
  font-size: 12px;
  opacity: .6;
}

.about-content p {
  margin: 2rem 0 2.6rem;
  opacity: .6;
}

/* media queries medium devices */

@media screen and (max-width: 1024px) {
  .aboutContainer {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about-me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about-content p {
    margin: 1rem 0 1.5rem;
  }
}

/* media queries small devices */

@media screen and (max-width: 600px) {
  .about-me {
    width: 60%;
    margin: 0 auto 3rem;
  }

  .about-card {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about-content {
    text-align: center;
  }

  .about-content p {
    margin: 1.5rem 0;
  }
}