header {
  padding-top: 7em;
}


.headerContainer {
  text-align: center;
  height: 100%;
  position: relative;
}

.headerSocials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .8rem;
  position: fixed;
  left: 2rem;
  bottom: 3rem;
}

/* vertical bar */
.headerSocials::after {
  content: '';
  height: 2rem;
  width: .1rem;
  background: var(--bdr-color2);
}


/* scroll down to contact*/

.scroll-down {
  position: fixed;
  right: 2rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 200;
  font-size: 1rem;
}

/* media queries medium devices */

@media screen and (max-width: 1024px) {
  header {
    height: 15vh;
  }

}

/* media queries small devices */

@media screen and (max-width: 600px) {
  header {
    height: 20vh;
  }

  .headerSocials,
  .scroll-down {
    display: none;
  }


}