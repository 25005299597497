nav {
  background-color: rgba(234, 212, 199, 0.478);
  width: max-content;
  display: block;
  padding: .7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  display: flex;
  gap: .8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);

}

nav a {
  background: transparent;
  padding: .8rem;
  border-radius: 50%;
  display: flex;
  font-size: 1.1rem;
}

nav a:hover {
  background: rgba(151, 123, 107, 0.205);

}