@import url('https: //fonts.googleapis.com/css2?family=Italiana&family=Roboto:wght@100&display=swap');

/* general styling */

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --bg-color: #FCF9F2;
  --btn-color1: #000;
  --btn-color2: #EFE7DC;
  --bdr-color1: #A68A64;
  --bdr-color2: #582F0E;
  --transition: all 400ms ease;
  /* change of width sizes for media queries */
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

@font-face {
  font-family: "Italiana-Regular";
  src: url(./fonts/Italiana-Regular.ttf);
}


/* scroll behavior will apply to entire html page */
html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg-color);
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}


h1 {
  font-size: 50px;
  font-family: 'Italiana';
}

h2 {
  padding: 1.5rem;
  font-family: 'Italiana';
  font-size: 40px;
}

h3,
h5 {
  font-family: 'Italiana';
  font-size: 25px;

}

h4 {
  font-family: 'Italiana';
  font-style: italic;
  color: var(--bdr-color2)
}

p,
small,
.btn,
.btn-primary {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

section {
  margin-top: 8rem;

}

.text-light {
  opacity: .25;
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
  opacity: .9;
}

a {
  font-family: 'Italiana';
  color: var(--bdr-color2);
}

a:hover {
  color: var(--bdr-color1);
}

.btn {
  width: max-content;
  display: inline-block;
  background-color: var(--btn-color1);
  color: var(--btn-color2);
  padding: .75em 1.2em;
  border-radius: .4em;
  cursor: pointer;
  border: 1px solid var(--btn-color1);
  transition: var(--transition);
}

.btn:hover {
  background-color: var(--bg-color);
  color: var(--btn-color1);
  border-color: transparent;
}

.btn-primary {
  background-color: var(--bg-color);
  color: var(--btn-color1);
  border-color: transparent;
}

.btn-primary:hover {
  color: var(--bdr-color1);
  border-color: var(--btn-color1);
}

/* media queries for medium devices */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6em;
  }
}

/* media queries for small devices */

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section h2 {
    margin-bottom: 2em;
  }
}